import VueI18n from 'vue-i18n';
import Vue from 'vue';

const messages = {
    en: {
        error: 'Error',
        bannedTitle: 'Important Notice',
        notFound: {
            title: 'Not found',
            desc: 'The survey was not found or is no longer valid.'
        },
        form: {
            fieldRequired: 'Additional field is required',
            fieldBooleanRequired: "Please mark the required checkboxes",
            privacyRequired: 'Please agree to the privacy notice',
            multipleDateSelection: '(multiple selection allowed)',
            userData: 'Personal data',
            comment: 'Comment',
            commentRequired: 'Comment is required',
            partnerId: 'Partner-ID',
            partnerIdRequired: 'Partner-ID is required',
            salutation: 'Salutation',
            salutationRequired: 'Provide your salutation',
            salutations: {
                male: 'Mr',
                female: 'Ms / Mrs',
                divers: 'Mx'
            },
            firstname: 'First Name',
            firstnameRequired: 'First Name is required',
            lastname: 'Last Name',
            lastnameRequired: 'Last Name is required',
            name: 'Name',
            street: 'Street and house number',
            streetTT: 'Street and house number (i.e. Kasernenstr. 23)',
            addressStreetRequired: 'Street is required',
            city: 'Zip code and city',
            cityTT: 'Zip code and city (i.e. 40213 Düsseldorf)',
            addressCityRequired: 'City is required',
            addressCityInvalid: 'Provide postcode and city in correct format (i.e. 40213 Düsseldorf)',
            company: 'Company',
            companyRequired: 'Company is required',
            email: 'E-Mail',
            emailRequired: 'E-Mail is required',
            emailFormat: 'E-Mail is invalid',
            phoneShort: 'Phone number',
            phone: 'Area code and number (i.e. 211 54079704)',
            phoneRequired: 'Phone number is required',
            phoneFormat: 'Phone number is invalid',
            vvId: 'gut beraten ID (VV-ID)',
            vvIdShort: 'VV-ID',
            vvIdRequired: 'VV-ID is required',
            vvIdInvalidFormat: 'VV-ID has to match this pattern: YYYYMMDD-XXXXXX-XX',
            vvIdHint: 'gut beraten ID / VV-ID (i.e. 20140417-123456-22)',
            vvIdInvalidExample: 'VV-ID ist not valid',
            addField: 'Additional information',
            submit: 'Send form',
            submitChange: 'Save changes',
            privacy: 'Privacy policy',
            textPlaceholder: 'Your input',
            requiredHint: 'All fields marked with an asterisk (*) are mandatory',
            customFieldRequired: '{name} is required',
            customFieldInvalid: '{name} does not correspond to the specified format. Hover your mouse over the input field to see filling instructions.',
            customFieldTooLong: 'Input of {name} is too long',
        },
        gender: {
            male: 'Mr',
            female: 'Mrs',
            divers: 'Mx'
        },
        success: {
            salutation: 'Dear { firstname } { lastname },',
            congrats: 'Congratulations',
            congrats_pass: 'Congratulations, the exam is considered passed.',
            congrats_line_1: 'Thank you for participating',
            congrats_line_2: 'You will receive an email with more information shortly.',
            dates: 'Your dates',
            data: 'Your personal data',
            additionalData: 'Your additional data',
        },
        survey: {
            salutation: 'Dear {firstname} {lastname},',
            salutation_anon: 'Dear participant,',
            multiple_choice_result: 'You achieved <b>{result}</b> out of <b>{total}</b> points in the multiple choice test. ',
            cert_download: 'Download certificate',
            answers: 'Your answers',
            required_question: 'Mandatory question',
            multiple_choice: 'Multiple choice',
            invalid_question: 'Question {index} of {total} is a mandatory question'
        },
        date: {
            bookedOut: 'Booked out',
            past: 'Closed',
            seatsAvailable: 'seats available'
        },
        deleted: {
            title: 'Deleted',
            desc: 'Your participation was completely deleted.'
        },
        backToForm: 'Back to survey form',
        back: 'Back',
        imprint: {
            imprint: 'Imprint',
            title: 'Imprint for the survey',
            domain: 'The domain {domain} is operated by',
            phone: 'Phone: ',
            fax: 'Fax: ',
            mail: 'E-mail: ',
            site: 'Internet: ',
            director: 'Authorized Managing Director: ',
            regCourt: 'Register court: ',
            regNumber: 'Register number: ',
            taxNumber: 'Sales tax identification number according to § 27 a sales tax law: ',
            personInCharge: 'Responsible for the content of this form according to § 18 Abs. 2 MStV',
            contactInfo: 'Contact details are available to be requested from the CSN. GmbH',
            disclaimer: 'Disclaimer',
            disclaimerText: 'Despite careful content control, we assume no liability for the content of external links. The operators of the linked pages are solely responsible for their content.',
            dataProtection: 'Note on data protection',
            dataProtectionText: 'Your data sent with the survey will be processed by the {company} and the service provider CSN. Communication Service Network GmbH processed to conduct the webinar.',
            dataProtectionPersonText: 'Your data sent with the survey will be processed by {company} and the service provider CSN. Communication Service Network GmbH processed to conduct the webinar.'
        },
        alreadyRegistered: 'You have already registered for this survey with your telephone number, e-mail address or vvId. A double participation is not possible.',
        banned: 'Registration with the e-mail address or telephone number you entered is currently not possible. Please contact the organizer for more information.',
        banned2: 'In some conferences mobile phones are disabled by default. In that case please call from your landline phone.',
        privacyPrefix: 'Information on the processing of your personal data can be found in the ',
        privacy: 'data protection declaration',
        privacyHeading: 'Hinweis zum Datenschutz:',
        privacy1: 'Your data sent with the participation will be processed by',
        privacy2: ' {company} and ',
        privacy3: 'the service provider CSN. Communication Service Network GmbH to conduct the webinar.'
    },
    de: {
        error: 'Fehler',
        bannedTitle: 'Wichtiger Hinweis',
        notFound: {
            title: 'Nicht gefunden',
            desc: 'Die Umfrage wurde nicht gefunden oder ist nicht mehr gültig.'
        },
        form: {
            dates: 'Übersicht der Termine',
            dateRequired: 'Wählen Sie einen Termin',
            fieldRequired: 'Füllen Sie alle notwendigen Zusatzfelder aus',
            fieldBooleanRequired: "Bestätigen Sie die notwendigen Kontrollkästchen",
            privacyRequired: 'Sie müssen den datenschutzrechtlichen Hinweisen zustimmen',
            multipleDateSelection: '(Mehrfachauswahl erlaubt)',
            userData: 'Persönliche Daten',
            comment: 'Bemerkungen',
            commentRequired: 'Bemerkungen ist ein Pflichtfeld',
            partnerId: 'Partner-ID',
            partnerIdRequired: 'Partner-ID ist ein Pflichtfeld',
            salutation: 'Anrede',
            salutationRequired: 'Geben Sie Ihre Anrede  an',
            salutations: {
                male: 'Herr',
                female: 'Frau',
                divers: 'Divers'
            },
            firstname: 'Vorname',
            firstnameRequired: 'Vorname ist ein Pflichtfeld',
            lastname: 'Nachname',
            lastnameRequired: 'Nachname ist ein Pflichtfeld',
            name: 'Name',
            street: 'Straße und Hausnummer',
            streetTT: 'Straße und Hausnummer (z.B.: Kasernenstr. 23)',
            addressStreetRequired: 'Straße ist ein Pflichtfeld',
            city: 'Postleitzahl und Ort',
            cityTT: 'Postleitzahl und Ort (z.B.: 40213 Düsseldorf)',
            addressCityRequired: 'Ort ist ein Pflichtfeld',
            addressCityInvalid: 'Geben Sie PLZ und Ort korrekt an (z.B. 40213 Düsseldorf)',
            company: 'Firma',
            companyRequired: 'Firma ist ein Pflichtfeld',
            email: 'E-Mail-Adresse',
            emailRequired: 'E-Mail-Adresse ist ein Pflichtfeld',
            emailFormat: 'E-Mail-Adresse ist ungültig',
            phoneShort: 'Telefonnummer',
            phone: 'Ortsvorwahl und Rufnummer (z.B.: 211 54079704)',
            phoneRequired: 'Telefonnummer ist ein Pflichtfeld',
            phoneFormat: 'Telefonnummer ist ungültig',
            vvId: 'gut beraten ID (VV-ID)',
            vvIdHint: 'gut beraten ID / VV-ID (z.B.: 20140417-123456-22)',
            vvIdShort: 'VV-ID',
            vvIdRequired: 'gut beraten ID (VV-ID) ist Pflichtfeld',
            vvIdInvalidFormat: 'gut beraten ID (VV-ID) muss folgendes Format erfüllen: JJJJMMTT-XXXXXX-XX',
            vvIdInvalidExample: 'gut beraten ID (VV-ID) ist ungültig',
            addField: 'Weitere Angaben',
            submit: 'Formular absenden',
            submitChange: 'Änderungen speichern',
            privacy: 'Datenschutzerklärung',
            textPlaceholder: 'Ihre Eingabe',
            requiredHint: '* Die mit einem Stern gekennzeichneten Felder sind Pflichtfelder',
            customFieldRequired: '{name} ist ein Pflichtfeld',
            customFieldInvalid: '{name} entspricht nicht dem vorgegebenem Format. Fahren Sie mit der Maus über das Eingabefeld, um Ausfüllhinweise anzeigen zu lassen.',
            customFieldTooLong: 'Die Eingabe von {name} ist zu lang',
        },
        success: {
            congrats: 'Herzlichen Glückwunsch',
            congrats_pass: 'Herzlichen Glückwunsch, die Prüfung gilt somit als bestanden.',
            congrats_cert: 'Ein Zertifikat wird Ihnen in Kürze per E-Mail zugestellt.',
            congrats_line_1: 'Vielen Dank für die Teilnahme',
            congrats_line_2: 'Sie erhalten in Kürze eine E-Mail mit weiteren Informationen',
            thanks_for_participating: 'Vielen  Dank für Ihre Teilnahme an dieser Umfrage.',
            mail_confirm: '',
            dates: 'Ihre Termine',
            data: 'Ihre persönlichen Daten',
            additionalData: 'Ihre Zusatzangaben',
        },
        survey: {
            salutation: 'Sehr geehrte(r) {firstname} {lastname},',
            salutation_anon: 'Sehr geehrte/r Teilnehmer/in,',
            multiple_choice_result: 'Sie haben in dem Multiple-Choice-Test  <b>{result}</b> von <b>{total}</b> Punkten erreicht.',
            cert_download: 'Zertifikat herunterladen',
            answers: 'Ihre Antworten',
            required_question: 'Pflichtfrage',
            multiple_choice: 'Mehrfachauswahl möglich',
            invalid_question: 'Frage {index} von {total} ist eine Pflichtfrage'
        },
        updated: {
            congrats: 'Herzlichen Glückwunsch',
            congrats_line_1: 'Sie haben Ihre Teilnahme zur Veranstaltung { title } erfolgreich geändert.',
            congrats_line_2: ''
        },
        date: {
            bookedOut: 'Ausgebucht',
            past: 'Geschlossen',
            seatsAvailable: 'Plätze frei'
        },
        deleted: {
            title: 'Gelöscht',
            desc: 'Ihre Anmledung wurde komplett gelöscht.'
        },
        backToForm: 'Zurück zur Umfrage',
        back: 'Zurück',
        imprint: {
            imprint: 'Impressum',
            title: 'Impressum für das Umfrageformular',
            domain: 'Die Domain {domain} wird betrieben von',
            phone: 'Telefon: ',
            fax: 'Telefax: ',
            mail: 'E-Mail: ',
            site: 'Internet: ',
            director: 'Vertretungsberechtigter Geschäftsführer: ',
            regCourt: 'Registergericht: ',
            regNumber: 'Registernummer: ',
            taxNumber: 'Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: ',
            personInCharge: 'Inhaltlich Verantwortlicher für dieses Formular gemäß § 18 Abs. 2 MStV',
            contactInfo: 'Kontaktdaten auf Anfrage bei der CSN. GmbH erhältlich',
            disclaimer: 'Haftungshinweis',
            disclaimerText: 'Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.',
            dataProtection: 'Hinweis zum Datenschutz',
            dataProtectionText: 'Ihre mit der Teilnahme übersandten Daten werden durch die {company} und den Dienstleister CSN. Communication Service Network GmbH zur Durchführung des Webinars verarbeitet.',
            dataProtectionPersonText: 'Ihre mit der Teilnahme übersandten Daten werden durch {company} und den Dienstleister CSN. Communication Service Network GmbH zur Durchführung des Webinars verarbeitet.'
        },
        alreadyRegistered: 'Sie haben sich bereits mit Ihrer Telefonnummer, E-Mail-Adresse oder gut beraten ID (VV-ID) angemeldet. Eine Doppelregistrierung ist nicht möglich.',
        banned: 'Eine Registrierung mit der von Ihnen eingegebenen Rufnummer oder E-Mail-Adresse ist nicht möglich. Bitte prüfen Sie diesbezüglich die Hinweise im Anmeldeformular oder wenden Sie sich an den Veranstalter.',
        banned2: 'In einigen Veranstaltungen sind Mobilfunknummern gesperrt. Weichen Sie in diesem Fall bitte auf eine Festnetznummer aus.',
        privacyPrefix: 'Hinweise zur Verarbeitung Ihrer personenbezogenen Daten erhalten Sie in der ',
        privacy: 'Datenschutzerklärung',
        privacyHeading: 'Hinweis zum Datenschutz:',
        privacy1: 'Ihre mit der Teilnahme  übersandten Daten werden durch',
        privacy2: ' die {company} und',
        privacy3: 'den Dienstleister CSN. Communication Service Network GmbH zur Durchführung des Webinars verarbeitet.'
    }
}

Vue.use(VueI18n);

// Create VueI18n instance with options
export default new VueI18n({
    locale: navigator.languages[0], // set locale
    messages, // set locale messages
})
