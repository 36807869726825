import DOMPurify from 'dompurify'

export function sanitizeCustomCheckbox(input: string): string{
    return DOMPurify.sanitize(input, {ALLOWED_TAGS: ['b', 'i', 'a', 'u']})
}

export function prepareCheckboxHtml(html: string): string {
    const sanitized = sanitizeCustomCheckbox(html);

    const parser = new DOMParser();
    const doc = parser.parseFromString(sanitized, "text/html");
    const links = doc.querySelectorAll("a");

    links.forEach((link) => {
        link.setAttribute("target", "_blank");
    });

    return doc.body.innerHTML;
}