



































































































































import {Component, Vue} from 'vue-property-decorator';
import Logo from "@/components/Logo.vue";
import Banner from "@/components/Banner.vue";
import {CustomFieldWithValueString, FieldWithValueBoolean, FieldWithValueString, UserData} from "@/models/UserData";
import {Locale} from "date-fns";
import UserDataItem from "@/components/UserDataItem.vue";
import {Page} from "@/store/form";
import {Field, Survey} from "@/models/Survey";
import {QuestionOption, QuestionType} from "@/models/Question";
import {fillPlaceholders, formatQuestionCount} from "@/utils/utils";
import {MultipleTextAnswer, SurveyQuestionAnswer} from "@/models/Response";
import {prepareCheckboxHtml, sanitizeCustomCheckbox} from "@/utils/sanitizeHtml";

@Component({
  methods: {prepareCheckboxHtml, sanitizeCustomCheckbox},
  components: {UserDataItem, Banner, Logo}
})
export default class SurveyResult extends Vue {

  private questionTypeEnum = QuestionType;
  private formatQuestionCount = formatQuestionCount;

  get form(): Survey | undefined {
    return this.$store.getters.form;
  }

  get userData(): UserData {
    return this.$store.getters.userData;
  }

  get locale(): Locale {
    return this.$store.getters.locale;
  }

  get page(): Page {
    return this.$store.getters.page;
  }

  get resultText(): string {
    if (this.form?.mail) {
      return fillPlaceholders(this.form, this.userData);
    }
    return ''
  }

  get showAnswers(): boolean {
    let mail = this.form?.mail;
    return mail === undefined || (mail.type === 'ANSWERS' || mail.type === 'ANSWERS_AND_PERSONAL_DATA')
  }

  get showPersonalData(): boolean {
    if (!this.form) return false;
    let mail = this.form.mail;
    return (mail === undefined || (mail.type === 'PERSONAL_DATA' || mail.type === 'ANSWERS_AND_PERSONAL_DATA')) && this.form.saveParticipantData;
  }

  get showAdditionalData(): boolean {
    if (!this.form) return false;
    let mail = this.form.mail;
    return (mail === undefined || (mail.type === 'PERSONAL_DATA' || mail.type === 'ANSWERS_AND_PERSONAL_DATA'))
        && (this.form.participantDataToSave.comment.show
            || this.form.participantDataToSave.vvId.show
            || this.form.participantDataToSave.partnerId.show
            || (this.form.participantDataToSave.customFields && this.form.participantDataToSave.customFields.length > 0
            || (this.form.customFields && this.form.customFields.length > 0)
            )
        )
  }

  filterSelectedRadio(answer: SurveyQuestionAnswer, options: Array<QuestionOption>): string {
    if (typeof answer.answer === "number") {
      let caption = options.filter(o => o.id === answer.answer)[0];
      return caption ? caption.caption : '-';
    } else {
      let a = answer.answer as Array<number>
      let caption = options.filter(o => o.id === a[0])[0];
      return caption ? caption.caption : '-';
    }
  }

  filterMultipleTexts(answers: Array<MultipleTextAnswer>, option: QuestionOption): string {
    let answer = answers.filter(a => a.id === option.id)[0].answer;
    return answer ? answer : '-';
  }

  get fieldsString(): Array<FieldWithValueString> {
    if (!this.form || !this.form.participantDataToSave.customFields) return [];
    return this.form?.participantDataToSave.customFields.map(f => {
      const value = this.userData.fieldValues.find(fieldValue => fieldValue.id === f.id);
      const field: Field = {
        id: f.id,
        title: f.caption,
        type: 'TEXT_LINE',
        required: f.required,
        idx: 0
      };
      return {field, value: value ? value.value : ''}
    });
  }

  get customFieldsString(): Array<CustomFieldWithValueString> {
    if (!this.form || !this.form.customFields || !this.userData.customFieldValues) return [];
    return this.form.customFields
        .map(field => {
          const fieldValue = this.userData.customFieldValues.find(fieldValue => fieldValue.fieldId === field.id);
          return {field, value: fieldValue ? fieldValue.value : ""};
        });
  }

  get fieldsBoolean(): FieldWithValueBoolean[] {
    if (!this.form || !this.form.participantDataToSave.customCheckboxes) return [];
    return this.form.participantDataToSave.customCheckboxes
        .map(customField => {
          const value = this.userData.booleanValues.find(fieldValue => fieldValue.id === customField.id);
          const field: Field = {
            id: customField.id,
            title: customField.caption,
            type: 'CHECKBOX',
            required: customField.required,
            idx: 0
          };
          return {field, value: value ? value.value === "true" : true}
        });
  }

  get privacyLabel(): string {
    return this.$store.getters.privacy;
  }

}
